






import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import UserList from '@/components/Mobile/UserList.vue';
export default Vue.extend({
  components: { Scaffold, UserList },
});
